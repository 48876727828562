import { template as template_cf5913f78cb74b3da908048d801e28e7 } from "@ember/template-compiler";
const FKText = template_cf5913f78cb74b3da908048d801e28e7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
